import {Controller} from "@hotwired/stimulus"

// properties on a.remove_fields:
// data-confirm-title
// data-confirm-text

// CODE EXAMPLE
// a{ data: { controller: 'confirm-action', confirm_text: confirm_text, confirm_title: confirm_title }}

export default class extends Controller {

    connect() {
        this.element.addEventListener('click', (ev) => this.first_click(ev))
    }

    first_click(eve) {
        const targ = this.element
        console.log('clicked!', targ)

        if (!targ.hasAttribute('data-asked-and-remove-now')) {

            const reveal = document.getElementById('confirm-action-reveal')
            const action_button = reveal.querySelector('#confirm_button')
            action_button.addEventListener('click', () => this.delete_row_action(reveal))

            // set text to reveal
            if (targ.hasAttribute('data-confirm-title')) {
                const title = reveal.querySelector('h1')
                title.innerHTML = targ.getAttribute('data-confirm-title')
            }
            if (targ.hasAttribute('data-confirm-text')) {
                const body = reveal.querySelector('p.body')
                body.innerHTML = targ.getAttribute('data-confirm-text')
            }

            // events
            eve.preventDefault()
            eve.stopImmediatePropagation()

            // open the reveal
            $(reveal).foundation('open')
        }
    }
    delete_row_action(reveal) {
        this.element.setAttribute('data-asked-and-remove-now', true)
        this.element.click()
        this.element.removeAttribute('data-asked-and-remove-now')
        $(reveal).foundation('close')
    }

}