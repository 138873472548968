import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        const modal_btn = this.element.querySelector('#show-modal')
        modal_btn.addEventListener('click', () => this.showModal())
    }

    showModal() {
        const modal = document.createElement('modal-tag')
        modal.setAttribute('title', 'Test-Titel')
        modal.setAttribute('message', 'Hallo Modal')
        document.body.append(modal)
    }

}